import { Grid as MuiGrid, List as MuiList, ListItem as MuiListItem, Stack as MuiStack } from '@mui/material'
import { styled } from '@mui/system'
import Button from '../../molecules/button'

const shouldForwardProp = (prop) => !['look', 'message', 'position'].includes(prop)

const Header = styled('header', { shouldForwardProp })`
	${({ message, position }) => (position === 'top' || !message ? `box-shadow: 0 0 54px 0 rgba(0, 0, 0, 0.09);` : '')}
`

const GridContainer = styled(MuiGrid)`
	padding: ${({ theme }) => theme.spacing(1)};
	${({ theme }) => theme.breakpoints.up('xsm')} {
		padding-left: ${({ theme }) => theme.spacing(1)};
		padding-right: ${({ theme }) => theme.spacing(1)};
	}
`

const LogoWrapper = styled('div')`
	max-width: 158px;
	width: 100%;
`

const StackStyled = styled(MuiStack, { shouldForwardProp })`
	padding-top: 7px;
	.mini-trigger {
		padding: 0;
	}
	.MuiSvgIcon-root {
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
	}
	.mini-trigger .MuiSvgIcon-root {
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
	}
`

const ListStyled = styled(MuiList)`
	padding: ${({ theme }) => theme.spacing(9, 2.75, 0)};
`

const ListItemStyled = styled(MuiListItem)`
	padding-bottom: ${({ theme }) => theme.spacing(1.25)};
	padding-top: ${({ theme }) => theme.spacing(1.25)};
`

const ButtonAction = styled(Button)`
	border-bottom: 0;
	.MuiButton-startIcon {
		margin-right: ${({ theme }) => theme.spacing(2.5)};
	}
	.MuiButton-iconSizeMedium.MuiButton-startIcon .MuiSvgIcon-fontSizeSmall {
		color: ${({ theme }) => theme.palette.primary.main};
		font-size: ${({ theme }) => theme.typography.pxToRem(24)};
	}
`

export { ButtonAction, GridContainer, Header, ListItemStyled, ListStyled, LogoWrapper, StackStyled }
